<div class="container-filter">
  <div class="input-search-box">
    <input class="input-search" type="text" placeholder="Buscar registro?" [(ngModel)]="filter">
    <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
  </div>
</div>


<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="tanque">
      <th mat-header-cell class="tanque-column" *matHeaderCellDef id="tanque">Tanque</th>
      <td mat-cell class="tanque-column" *matCellDef="let element"> {{ element.tanqueNome }}</td>
    </ng-container>

    <ng-container matColumnDef="propriedade">
      <th mat-header-cell class="propriedade-column" *matHeaderCellDef id="local">Propriedade</th>
      <td mat-cell class="propriedade-column" *matCellDef="let element"> {{ element.propriedadeNome }}</td>
    </ng-container>

    <ng-container matColumnDef="convAlimentar">
      <th mat-header-cell *matHeaderCellDef id="convAlimentar">C.A</th>
      <td mat-cell *matCellDef="let element"> {{ element?.convAlimenticia }}</td>
    </ng-container>

    <ng-container matColumnDef="gpd">
      <th mat-header-cell *matHeaderCellDef id="gpd">GPD</th>
      <td mat-cell *matCellDef="let element">{{ element.gpd }}</td>
    </ng-container>

    <ng-container matColumnDef="temperatura">
      <th mat-header-cell *matHeaderCellDef id="temperatura">Temp.</th>
      <td mat-cell *matCellDef="let element">{{ element.temperatura }}</td>
    </ng-container>

    <ng-container matColumnDef="oxigenio">
      <th mat-header-cell *matHeaderCellDef id="oxigenio">Ox.</th>
      <td mat-cell *matCellDef="let element">{{ element.oxigenio }}</td>
    </ng-container>

    <ng-container matColumnDef="racao">
      <th mat-header-cell class="racao-column" *matHeaderCellDef id="racao">Ração</th>
      <td mat-cell class="racao-column" *matCellDef="let element">{{ element.racao }}</td>
    </ng-container>

    <ng-container matColumnDef="coleta">
      <th mat-header-cell class="data-coleta-column" *matHeaderCellDef id="coleta">Data Coleta</th>
      <td mat-cell class="data-coleta-column" *matCellDef="let element">{{ element.coleta | dateFormat}}</td>
    </ng-container>

    <ng-container matColumnDef="registro">
      <th mat-header-cell class="hora-coleta-column" *matHeaderCellDef id="registro">Hora Coleta</th>
      <td mat-cell class="hora-coleta-column" *matCellDef="let element">{{ element.hrColeta | timeFormatPipe }}</td>
    </ng-container>

    <ng-container matColumnDef="detalhe">
      <th mat-header-cell class="detalhe-column" *matHeaderCellDef id="header-right">Detalhes</th>
      <td mat-cell class="detalhe-column" *matCellDef="let element" id="detalhe">
        <mat-icon class="icon-search-info material-symbols-rounded" (click)="redirectToDetails(element?.id)">search</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div id="paginacao" class="flex-align-itens-center">
    <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
    <div class="flex-align-itens-center">
      <ng-container *ngFor="let number of getSequence(totalPage)">
        <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
      </ng-container>
    </div>
    <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
  </div>
</div>

