<div class="container-full">
  <img class="logo-full" src="assets/conectre-72.svg">

  <div class="itens">
    <h4 class="topic hidden-effect invisible-effect">Gestão</h4>
    <ul>
      <ng-container *ngFor="let g of gestaoItems()">
        <li><a href="{{ g.route }}"><mat-icon class="icon material-symbols-rounded">{{ g.icon }}</mat-icon>
            <span class="item" *ngIf="!sideNavCollapsed">{{ g.title }}</span></a></li>
      </ng-container>
      <li class="dropdown">
        <a class="cadastros-button" (click)="openDropdown()">
          <mat-icon class="icon material-symbols-rounded">person_add</mat-icon>
          <span class="item" *ngIf="!sideNavCollapsed">Cadastros</span>
          <mat-icon class="arrow-icon material-symbols-rounded hidden-effect invisible-effect">keyboard_arrow_down</mat-icon>
        </a>
        <div class="dropdown-content">
          <a href="empresa/cadastrar" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'"><img src="../../assets/subitem-icon.svg" alt="Cadastros">Empresa</a>
          <a href="tecnico/cadastrar" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'"><img src="../../assets/subitem-icon.svg" alt="Cadastros">Técnico</a>
          <a href="produtor/cadastrar" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO' || storage.getRole() === 'EMPRESA'"><img src="../../assets/subitem-icon.svg" alt="Cadastros">Produtor</a>
          <a href="produtor/cadastrar" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO' || storage.getRole() === 'EMPRESA'"><img src="../../assets/subitem-icon.svg" alt="Cadastros">Tanques</a>
          <a href="propriedade/cadastrar" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO' || storage.getRole() === 'PRODUTOR'"><img src="../../assets/subitem-icon.svg" alt="Cadastros">Propriedade</a>
        </div>
      </li>
    </ul>
  </div>

<!--  <div class="itens">-->
<!--    <h4 class="topic topic-child-2 hidden-effect invisible-effect">Sistema</h4>-->
<!--    <ul>-->
<!--      <ng-container *ngFor="let g of sistemaItems()">-->
<!--        <li class="sidemenu-secondary"><a href="{{ g.route }}"><mat-icon class="icon material-symbols-rounded">{{ g.icon }}</mat-icon>-->
<!--            <span class="item" *ngIf="!sideNavCollapsed">{{ g.title }}</span></a></li>-->
<!--      </ng-container>-->
<!--    </ul>-->
<!--  </div>-->

  <!-- <div class="menu-button" (click)="openSideMenu()">
    <span class="menu-icon-line first-line"></span>
    <span class="menu-icon-line second-line"></span>
    <span class="menu-icon-line third-line"></span>
  </div> -->
</div>
