<div class="tanque-infos">
  <div class="header-infos">
    <select id="tanque" [(ngModel)]="selectedTanque">
      <option class="select-placeholder" value="" disabled selected>Selecione um tanque...</option>
      <option *ngFor="let tanque of tanques" [value]="tanque.id">{{ tanque.nome }}</option>
    </select>
    <span class="selected-tanque-name">{{ selectedTanqueName }}</span>
  </div>

  <app-tabs-tanque class="table-infos" [tanqueId]="selectedTanque"></app-tabs-tanque>
</div>


<div style="margin-top: 100px;">
  <h3 class="historic-title">Histórico de registro</h3>
</div>

<app-table-registro-historico></app-table-registro-historico>