<div style="position: relative; min-height: 85vh">
  <div class="container-header">
    <a href="/inicio"><mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon></a>
    <span class="back-text">Voltar para a página principal</span>
  </div>

  <div class="container-form">
    <span style="font-size: 12px; color: #A1A1AA">Dados Pessoais</span>
    <form [formGroup]="form">
      <div class="row g-3">
        <div class="col-3">
          <label id="avatar" for="avatarInput" class="form-label">Foto</label>
          <div class="input-group">
            <input type="file" id="avatarInput" (change)="handleImageUpload($event)" accept="image/*"
                   style="display: none">
            <label for="avatarInput" class="input-group-text" style="cursor: pointer;">
              <div
                style="width: 40px; height: 40px; border-radius: 50%; background-color: #ccc; display: flex; justify-content: center; align-items: center;">
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-3">
          <label id="nome" for="nome" class="form-label required">Nome</label>
          <div class="input-group">
            <input type="text" id="nomeInput" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="nome" aria-describedby="nome">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('nomeInput')">edit</mat-icon>
          </div>
        </div>
        <div class="col-3">
          <label for="cpf" class="form-label required">CPF</label>
          <div class="input-group">
            <input type="email" id="cpf" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="cpf" appCnpjCpfMask [maxLength]="14">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('cpf')">edit</mat-icon>
          </div>
        </div>
        <div class="col-3">
          <label id="celular" for="celular" class="form-label required">Celular</label>
          <div class="input-group">
            <input type="text" id="celularInput" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="celular" aria-describedby="empresaImage" appCelularMask [maxLength]="15">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('celularInput')">edit</mat-icon>
          </div>
        </div>
        <div class="col-3">
          <label id="email" for="email" class="form-label required">E-mail</label>
          <div class="input-group">
            <input type="text" id="emailInput" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="email" aria-describedby="empresaImage">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('emailInput')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-3">
          <label id="empresa" for="empresa" class="form-label required">Empresa</label>
          <select class="form-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.id">{{ empresa.razaoSocial }}</option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div class="actions-footer">
    <button class="button-no-border" style="margin-right: 10px">Limpar dados</button>
    <button [disabled]="!form.valid" (click)="onSubmit()" [ngClass]="{'button-disabled': !form.valid}">{{ isEditando ? 'Salvar Alterações' : 'Cadastrar Produtor' }}
    </button>
  </div>

  <ng-container *ngIf="produtorId != null">
    <div class="tipography">
      <h3>Propriedades vinculadas</h3>
    </div>

    <app-table-propriedade-vinculado [produtorId]="produtorId"></app-table-propriedade-vinculado>
  </ng-container>

</div>
