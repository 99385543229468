<div style="position: relative; min-height: 85vh">
  <div class="container-header">
    <div class="back-box">
      <a href="">
        <mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon>
      </a>
      <span class="back-text">Voltar aos detalhes do produtor</span>
    </div>
    <div class="register-info">
      <span class="tec-info">
        <span>Cadastrado pelo técnico: </span>
        <span class="tec-name">Diogo Garbo</span>
      </span>

      <span class="date-info">
        <span>Última edição: </span>
        <span class="edited-date">08 de Maio, 2024</span>
      </span>
    </div>
  </div>

  <div class="container-form">
    <span style="font-size: 14px; color: #6D6D6D">Dados da Propriedade</span>
    <form [formGroup]="form">
      <div class="row g-3">
        <div class="col-6">
          <label id="nomeLabel" for="nome" class="form-label required">Nome</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="nome" id="nome" aria-describedby="nomeImage">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>

        <div class="col-6">
          <label id="produtorLabel" for="produtor" class="form-label required">Produtor</label>
          <select class="form-select" formControlName="produtor" id="produtor">
            <option *ngFor="let produtor of produtores" [value]="produtor.id">{{ produtor.nome }}</option>
          </select>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-6" formGroupName="endereco">
          <label for="logradouro" class="form-label required">Logradouro</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="logradouro" id="logradouro">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>

        <div class="col-2" formGroupName="endereco">
          <label for="cep" class="form-label required">CEP</label>
          <div class="input-group">
            <input type="text" class="form-control required" formControlName="cep" id="cep" appCepMask
                   (blur)="getEnderecoViaCep()" [maxLength]="9">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>

        <div class="col-4" formGroupName="endereco">
          <label for="localidade" class="form-label required">Cidade</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="localidade" id="localidade">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3" formGroupName="endereco">
        <div class="col-6">
          <label for="complemento" class="form-label">Complemento</label>
          <div class="input-group" style="padding-bottom: 0">
            <input type="text" class="form-control" formControlName="complemento" id="complemento"
                   style="margin-bottom: 0">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
          <span style="margin-top: 5px; font-size: 12px; color: #52525B;">Em caso de área rural, informar latitude e
            longitude</span>
        </div>

        <div class="col-2">
          <label for="numero" class="form-label required">Número</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="numero" id="numero">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="bairro" class="form-label required">Bairro</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="bairro" id="bairro">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="uf" class="form-label required">Estado</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="uf" id="uf" [maxLength]="2">
            <mat-icon class="icon edit-icon material-symbols-rounded">edit</mat-icon>
          </div>
        </div>
      </div>
    </form>

    <div class="container-buttons">
      <ng-container *ngIf="exibirTanque">
      <button class="button-no-border" style="margin-right: 10px" (click)="onDownload()">Download Medições</button>
      </ng-container>
      <button class="button-no-border" style="margin-right: 10px">Limpar</button>
      <button [disabled]="!form.valid" (click)="onSubmit()" [ngClass]="{'button-disabled': !form.valid}">
        Salvar alteração
      </button>
    </div>
  </div>

  <ng-container *ngIf="exibirTanque">
    <div class="tanque-infos">
      <div class="header-infos">
      <span class="select-title" (click)="selectOpen()">
        Tanque Sol
        <mat-icon class="menu-arrow-icon-select material-symbols-rounded">keyboard_arrow_down</mat-icon>
      </span>
        <div class="select-infos">
          <span>Piscicultura Sol nascente</span>
          <ul>
            <li>Tanque Sol</li>
            <li>Tanque Lua</li>
            <li>Tanque Nuvem</li>
            <li>Tanque Planeta</li>
          </ul>
        </div>
        <span> Piscicultura Nascente</span>
      </div>

      <app-tabs-tanque class="table-infos" [tanqueId]="1"></app-tabs-tanque>

      <span class="footer-infos">
      <span>
        Registro realizado em:
        <span class="date-info-footer">
          08 de Maio, 2024 às 19h40
        </span>
      </span>
    </span>
    </div>
  </ng-container>


</div>
