<div>
  <div class="button-close-div">
    <mat-icon class="icon-close material-symbols-rounded" (click)="fecharQuadrado()">close</mat-icon>
  </div>

  <div class="container-header">
    <h4>Filtragem</h4>
    <h4 id="text-limpar" (click)="limparDados()">Limpar todos</h4>
  </div>

  <mat-divider></mat-divider>

  <div class="container-sub-header">
    <h4 id="text-filtro">Filtros aplicados</h4>
    <h4 *ngIf="nenhumSelecionado">Nenhum selecionado</h4>
  </div>
</div>

<div class="container-chips">
  <mat-chip-set>
    <mat-chip *ngFor="let chip of checkedRazaoSocial">
      <span class="filter-type">Empresa</span>
      {{ chip }}
      <mat-icon class="icon-filter-remove material-symbols-rounded" (click)="toggleChip(chip, checkedRazaoSocial)" (click)="desativarCheckbox(chip, '')">close</mat-icon>
    </mat-chip>
  </mat-chip-set>

  <mat-chip-set>
    <mat-chip *ngFor="let chip of checkedQntEquipamentos">
      <span class="filter-type">Equipamentos</span>
      {{ chip }}
      <mat-icon class="icon-filter-remove material-symbols-rounded" (click)="toggleChip(chip, checkedQntEquipamentos)" (click)="desativarCheckbox(chip, 'equipamento')">close</mat-icon>
    </mat-chip>
  </mat-chip-set>

  <mat-chip-set>
    <mat-chip *ngFor="let chip of checkedQntPropriedades">
      <span class="filter-type">Propriedades</span>
      {{ chip }}
      <mat-icon class="icon-filter-remove material-symbols-rounded" (click)="toggleChip(chip, checkedQntPropriedades)" (click)="desativarCheckbox(chip, 'propriedade')">close</mat-icon>
    </mat-chip>
  </mat-chip-set>
</div>

<div class="container-options">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Empresa
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="checkbox-container">
        <mat-checkbox [color]="'accent'" class="checkbox-item" *ngFor="let empresa of razaoSocial; let i = index" (change)="toggleChip(empresa, checkedRazaoSocial)" [(ngModel)]="checkboxesAtivos[empresa]">
          {{ empresa }}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Equipamentos
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="checkbox-container">
        <mat-checkbox [color]="'accent'" class="checkbox-item" *ngFor="let opcao of qntEquipamento" (change)="toggleChip(opcao, checkedQntEquipamentos)" [(ngModel)]="checkboxesAtivos['equipamento' + opcao]">
          {{ opcao }}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Propriedades
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="checkbox-container">
        <mat-checkbox [color]="'accent'" class="checkbox-item" *ngFor="let opcao of qntPropriedade" (change)="toggleChip(opcao, checkedQntPropriedades)" [(ngModel)]="checkboxesAtivos['propriedade' + opcao]">
          {{ opcao }}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<div id="container-footer">
  <button class="button-geral-full" type="button" (click)="filter()" [disabled]="nenhumSelecionado" [ngClass]="{'button-disabled': nenhumSelecionado}">Ver resultados</button>
</div>
