<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Empresas" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'">
    <div class="tab-container">
      <div class="column">
        <h4>Empresas</h4>
        <h1>{{ empresaQnt }}</h1>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Técnicos"
           *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'">
    <div class="tab-container">
      <div class="column">
        <h4>Técnicos</h4>
        <h1>{{ tecnicoQnt }}</h1>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Produtores"
           *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO' || storage.getRole() === 'EMPRESA'">
    <div class="tab-container">
      <div class="column">
        <h4>Produtores</h4>
        <h1>{{ produtorQnt }}</h1>
      </div>
      <div class="column">
        <h4>Propriedades</h4>
        <h1>{{ propriedadeQnt }}</h1>
      </div>
      <div class="column">
        <h4>Equipamentos</h4>
        <h1>{{ equipamentoQnt }}</h1>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

