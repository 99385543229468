<div class="container-header">
  <h4 id="titulo">Novo Administrador</h4>
  <mat-icon class="icon-close material-symbols-rounded" (click)="closeModal()">close</mat-icon>
</div>

<div class="container-form">
  <form [formGroup]="form">
    <div class="row g-3">
      <div class="col-3">
        <label id="avatar" for="avatarInput" class="form-label required">Foto de Perfil</label>
        <div class="input-group" style="width: 72px; border-radius: 50%;">
          <div class="icon-add-image">
            <input type="file" id="avatarInput" (change)="handleImageUpload($event)" accept="image/*" style="display: none">
            <label for="avatarInput" class="avatar-input-Label input-group-text" style="cursor: pointer;">
              <div class="none-image"></div>
            <mat-icon class="icon-image_1 material-symbols-rounded">person</mat-icon>
            <mat-icon class="icon-image_2 material-symbols-rounded">add</mat-icon>
          </label>
        </div>
        </div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-6">
        <label for="nome" class="form-label small-label required">Nome</label>
        <input type="text" class="form-control form-adm" formControlName="nome" id="nome" placeholder="EX.: João da Silva">
      </div>
      <div class="col-6">
        <label for="email" class="form-label small-label required">E-mail</label>
        <input type="text" class="form-control form-adm" formControlName="email" id="email" placeholder="EX.: joao@email.com">
      </div>
    </div>
    <div class="row g-3">
      <div class="col-6">
        <label for="celular" class="form-label small-label required">Celular</label>
        <input type="text" class="form-control form-adm" formControlName="celular" id="celular" placeholder="EX.: (44) 99999-9999" appCelularMask [maxLength]="15">
      </div>
      <div class="col-6">
        <label for="cargo" class="form-label small-label required">Cargo</label>
        <input type="text" class="form-control form-adm" formControlName="cargo" id="cargo" placeholder="EX.: Diretor comercial">
      </div>
    </div>
  </form>
</div>

<div class="actions-footer">
  <button class="button-white" style="margin-right: 10px" (click)="closeModal()">Cancelar</button>
  <button [disabled]="form.invalid" (click)="onSubmit()" [ngClass]="{'button-disabled': !form.valid}">Cadastrar</button>
</div>
