<div style="position: relative; min-height: 85vh">
  <div class="container-header">
    <a href="/inicio"><mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon></a>
    <span class="back-text">Voltar para a página principal</span>
  </div>

  <div class="container-form">
    <span style="font-size: 12px; color: #A1A1AA">Dados Pessoais</span>
    <form [formGroup]="form">
      <div class="row g-3">
        <div class="col-3">
          <label id="avatar" for="avatarInput" class="form-label required">Foto</label>
          <div class="input-group">
            <input type="file" id="avatarInput" (change)="handleImageUpload($event)" accept="image/*"
                   style="display: none">
            <label for="avatarInput" class="input-group-text" style="cursor: pointer;">
              <div
                style="width: 40px; height: 40px; border-radius: 50%; background-color: #ccc; display: flex; justify-content: center; align-items: center;">
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-4">
          <label id="nome" for="nome" class="form-label required">Nome</label>
          <div class="input-group">
            <input id="nomeInput" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="nome" aria-describedby="nome">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('nomeInput')">edit</mat-icon>
          </div>
        </div>
        <div class="col-2">
          <label id="cpf" for="cpf" class="form-label required">CPF</label>
          <div class="input-group">
            <input id="cpfInput" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="cpf" appCnpjCpfMask [maxLength]="14" aria-describedby="empresaImage">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('cpfInput')">edit</mat-icon>
          </div>
        </div>
        <div class="col-3">
          <label id="celular" for="celular" class="form-label required">Celular</label>
          <div class="input-group">
            <input id="celularInput" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="celular" appCelularMask [maxLength]="15" aria-describedby="empresaImage">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('celularInput')">edit</mat-icon>
          </div>
        </div>
        <div class="col-3">
          <label for="email" class="form-label required">E-mail</label>
          <div class="input-group">
            <input id="email" type="email" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="email" id="email">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('email')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-4">
          <label id="empresa" for="empresa" class="form-label required">Empresa</label>
          <select class="form-select" formControlName="empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.id">{{ empresa.razaoSocial }}</option>
          </select>
        </div>

        <ng-container formGroupName="endereco">
          <div class="col-2">
            <label id="cep" for="cep" class="form-label required">CEP</label>
            <div class="input-group">
              <input id="cepInput" type="text" class="form-control required" [ngClass]="{'click-disabled': isEditando}" formControlName="cep" appCepMask (blur)="getEnderecoViaCep()">
              <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('cepInput')">edit</mat-icon>
            </div>
          </div>

          <div class="col-3">
            <label id="localidade" for="localidade" class="form-label required">Cidade</label>
            <div class="input-group">
              <input id="localidadeInput" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="localidade">
              <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('localidadeInput')">edit</mat-icon>
            </div>
          </div>

          <div class="col-3">
            <label id="logradouro" for="logradouro" class="form-label required">Logradouro</label>
            <div class="input-group">
              <input id="logradouroInput" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="logradouro">
              <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('logradouroInput')">edit</mat-icon>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row g-3" formGroupName="endereco">
        <div class="col-4">
          <label for="bairro" class="form-label required">Bairro</label>
          <div class="input-group">
            <input id="bairro" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="bairro">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('bairro')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label id="" for="" class="form-label required">Número</label>
          <div class="input-group">
            <input id="numero" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="numero">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('numero')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="uf" class="form-label required">Estado</label>
          <div class="input-group">
            <input id="uf" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="uf">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('uf')">edit</mat-icon>
          </div>
        </div>

        <div class="col-4">
          <label for="complemento" class="form-label">Complemento</label>
          <div class="input-group" style="padding-bottom: 0">
            <input id="complemento" type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="complemento" style="margin-bottom: 0">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('complemento')">edit</mat-icon>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="actions-footer">
    <button class="button-no-border" style="margin-right: 10px">Limpar dados</button>
      <button [disabled]="!form.valid" (click)="onSubmit()" [ngClass]="{'button-disabled': !form.valid}">
        {{ isEditando ? 'Salvar Alterações' : 'Cadastrar Técnico' }}
    </button>
  </div>

  <div class="tipography">
    <h3>Produtor vinculado</h3>
  </div>

  <app-table-produtor-vinculado></app-table-produtor-vinculado>

</div>

<div id="overlayConfirm">
  <app-overlay-confirmacao [action]=" 'salvar'" (onSave)="onSubmit()"></app-overlay-confirmacao>
</div>


