<div class="container-header">
  <h4 id="titulo">Novo grupo</h4>
    <img src="assets/close.svg" alt="Imagem Redonda" style="width: 15px" (click)="closeModal()">
</div>


<div class="container-form">
  <form [formGroup]="form">
    <div class="row g-3">
      <div class="col-12">
        <label for="grupo" class="form-label required">Grupo</label>
        <input type="text" class="form-control" id="grupo" aria-describedby="empresaImage">
      </div>
    </div>
    <div class="row g-3">
      <div class="col-12">
        <label for="endereco" class="form-label required">Endereço</label>
        <input type="text" class="form-control" id="endereco" aria-describedby="empresaImage">
      </div>
    </div>
  </form>
</div>

<div class="actions-footer">
  <button class="button-white" style="margin-right: 10px">Cancelar</button>
  <button>Cadastrar</button>
</div>
