<div class="container-filter">
  <div class="input-search-box">
    <input class="input-search" type="text" placeholder="O que você procura?" [(ngModel)]="filter">
    <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
  </div>
  <div class="buttons-box">
    <button type="button" class="button-no-border filter-button" style="padding-left: 12px;" (click)="clearSearch()">Limpar pesquisa</button>
    <div class="box-buttons-2">
      <button type="button" class="button-geral" style="margin-right: 10px" (click)="redirectToEmpresa()">Nova empresa</button>
      <button type="button" class="button-white filter-button" (click)="exibirQuadrado()">
        <div class="filters-numbers" *ngIf="filterQtd > 0">{{ filterQtd }}</div>
        Filtrar
        <mat-icon class="icon-filter material-symbols-rounded">filter_list</mat-icon>
      </button>
    </div>
  </div>
</div>


<div class="mat-elevation-z2 transition-effect">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="razaoSocial">
      <th mat-header-cell class="razaoSocial-column" *matHeaderCellDef id="empresa">Empresa</th>
      <td mat-cell class="razaoSocial-column" *matCellDef="let element">{{ element.razaoSocial }}</td>
    </ng-container>

    <ng-container matColumnDef="administradores">
      <th mat-header-cell *matHeaderCellDef id="adm">Administrador</th>
      <td mat-cell *matCellDef="let element" class="img-group">
        <ng-container *ngIf="element.administradores && element.administradores.length > 0">
          <ng-container *ngFor="let admin of element.administradores; let i=index">
            <div class="perfil-img" *ngIf="i < 4">{{ getInitials(admin.nome) }}</div>
          </ng-container>

          <ng-container *ngIf="element.administradores.length > 4">
            <div class="number-resting-box">
              <span class="number-resting">+{{ element.administradores.length - 4 }}</span>
            </div>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="cnpjCpf">
      <th mat-header-cell class="cnpj-column" *matHeaderCellDef id="cnpj">CNPJ/CPF</th>
      <td mat-cell class="cnpj-column" *matCellDef="let element"> {{ element.cnpjCpf | cpfCnpj }}</td>
    </ng-container>

    <ng-container matColumnDef="cidade">
      <th mat-header-cell class="cidade-column" *matHeaderCellDef id="cidade">Local</th>
      <td mat-cell class="cidade-column" *matCellDef="let element"> {{ element?.endereco?.localidade }} - {{ element?.endereco?.uf }}</td>
    </ng-container>

    <ng-container matColumnDef="detalhe">
      <th mat-header-cell *matHeaderCellDef id="header-right">Detalhes</th>
      <td mat-cell *matCellDef="let element" id="detalhe">
        <mat-icon class="icon-search-info material-symbols-rounded" (click)="redirectToDetails(element?.id)">search</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div id="paginacao" class="flex-align-itens-center">
    <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
    <div class="flex-align-itens-center">
      <ng-container *ngFor="let number of getSequence(totalPage)">
        <span id="paginacao-number" (click)="paginado(number)" [ngClass]="{'current-page': number === pageNumber + 1}">{{ number }}</span>
      </ng-container>
    </div>
    <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
  </div>
</div>


<div id="overlay">
  <app-empresa-filter (filtroAlterado)="onFiltroAlterado($event)" style="position: relative; height: 100%; display: block;"></app-empresa-filter>
</div>

<div id="filter-wall"></div>
